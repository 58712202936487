import puzzlesWithSolutions from './puzzles'

export default {
  meta_title: 'Puzzles | PennyLane',
  meta_description: 'Can you solve these quantum puzzles?',
  meta_image: 'https://assets.cloud.pennylane.ai/pennylane_website/generic/website_preview_image.png',
  title: 'Puzzles',
  sub_title: 'Can you solve these quantum puzzles?',
  puzzle_details: puzzlesWithSolutions,
  footer_text: `These puzzles are licensed under [CC BY-ND 4.0](http://creativecommons.org/licenses/by-nd/4.0/). <br/>Feel free to use them in presentations and outreach!`,
}
