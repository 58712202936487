/* Puzzle Images and Solutions */

/*
 * Guidelines for adding new puzzles:
 * All puzzles should be uploaded to the Pennylane Web Assets repo
 * - https://github.com/XanaduAI/pennylane-web-assets
 * - Upload the puzzle image to the puzzles folder
 * - the image name should be the puzzle solution (lowercase, separated by underscores)
 * Each image should have a corresponding description for screen readers
 * - e.g. 'bacon_shor.png' should have an alt text of 'Part 1: Slices of bacon; Part 2: Tropical Island; Part 3: Laptop'
 * Solution should be lowercase, separated by spaces
 * - e.g. 'bacon shor'
 */

export type Puzzle = {
  image: string
  solution: string
  alt: string
}

const puzzles: Puzzle[] = [
  {
    image: 'https://assets.cloud.pennylane.ai/puzzles/barren_plateau.png',
    alt: 'Guess the term from the clues: a member of the family Ursidae; another word for a chicken; something round and flat you would eat off; what Britain voted to leave in 2016.',
    solution: 'Barren Plateau',
  },
  {
    image: 'https://assets.cloud.pennylane.ai/puzzles/tensor_network.png',
    alt: 'Guess the term from the clues: two hours until noon; something for cutting wood; something for catching butterflies; what you do from 9 to 5.',
    solution: 'Tensor Network',
  },
  {
    image: 'https://assets.cloud.pennylane.ai/puzzles/stabilizer_code.png',
    alt: 'Guess the term from the clues: where horses live; the window to the soul; zero without the oh; a tasty fish.',
    solution: 'Stabilizer Code',
  },
  {
    image: 'https://assets.cloud.pennylane.ai/puzzles/qubit_tapering.png',
    alt: 'Guess the term from the clues: a 3D square; computers, networks, and the like; what we used to record television shows on; exchanged at a wedding.',
    solution: 'Qubit Tapering',
  },
  {
    image: 'https://assets.cloud.pennylane.ai/puzzles/parameter_shift.png',
    alt: 'Guess the term from the clues: pear minus e plus a; the SI unit for length; what librarians say to you; foot without the oo.',
    solution: 'Parameter Shift',
  },
  {
    image: 'https://assets.cloud.pennylane.ai/puzzles/hartree_fock.png',
    alt: 'Guess the term from the clues: an organ for pumping blood; a woody plant; a good hunting bird minus the h and plus an f.',
    solution: 'Hartree Fock',
  },
  {
    image: 'https://assets.cloud.pennylane.ai/puzzles/hamiltonian.png',
    alt: 'Guess the term from the clues: the meat of a pig; an item of Scottish dress, minus the k; another word for shallot.',
    solution: 'Hamiltonian',
  },
  {
    image: 'https://assets.cloud.pennylane.ai/puzzles/fault_tolerance.png',
    alt: 'Guess the term from the clues: the season between summer and winter; the opposite of short; the 18th letter of the alphabet; members of the family Formicidae.',
    solution: 'Fault Tolerance',
  },
  {
    image: 'https://assets.cloud.pennylane.ai/puzzles/error_correction.png',
    alt: 'Guess the term from the clues: what we breathe; what pirates say; the opposite of incorrect; the lower part of a leg.',
    solution: 'Error Correction',
  },
  {
    image: 'https://assets.cloud.pennylane.ai/puzzles/bell_inequality.png',
    alt: 'Guess the term from the clues: something that sounds nice when you ring it; the opposite of out; the second vowel; an Australian marsupial that eats eucalyptus leaves; the third vowel; a favourite British drink.',
    solution: 'Bell Inequality',
  },
  {
    image: 'https://assets.cloud.pennylane.ai/puzzles/classical_shadow.png',
    alt: 'Guess the term from the clues: where learning happens; a tool for harvesting; cast by objects in the way of light.',
    solution: 'Classical Shadows',
  },
  {
    image: 'https://assets.cloud.pennylane.ai/puzzles/linear_combination.png',
    alt: 'Guess the term from the clues: drawn with a pencil and a ruler; an organ for hearing; for getting knots out of hair; what you put rubbish in; between 7 and 9; a charged atom.',
    solution: 'Linear Combination',
  },
  {
    image: 'https://assets.cloud.pennylane.ai/puzzles/bacon_shor.png',
    alt: 'Guess the term from the clues: tastes good with egg and chips; where land meets sea.',
    solution: 'Bacon Shor Code',
  },
  {
    image: 'https://assets.cloud.pennylane.ai/puzzles/haar_measurement.png',
    alt: 'Guess the term from the clues: Rapunzel had a lot of it; what you would do with a ruler, a stopwatch, a multimeter, and more.',
    solution: 'Haar Measurement',
  },
  {
    image: 'https://assets.cloud.pennylane.ai/puzzles/unitary_operator.png',
    alt: 'Guess the term from the clues: a singular amount; like furry; fancy singing; eating in the past; an OR gate.',
    solution: 'Unitary Operator',
  },
  {
    image: 'https://assets.cloud.pennylane.ai/puzzles/coherence_time.png',
    alt: 'Guess the term from the clues: a bovine; what you do with ears; members of the family Formicidae; what clocks tell you.',
    solution: 'Coherence Time',
  },
]

export default puzzles
