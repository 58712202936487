import Slider, { Settings } from 'react-slick'
import puzzles from '../content/puzzles_page'
import Image from '../components/Image/Image'
import Seo from '../components/seo'
import MarkdownText from '../components/MarkdownText/MarkdownText'

const PuzzlesPage = () => {
  const slickSettings: Settings = {
    dots: false,
    infinite: true,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    accessibility: true,
  }

  return (
    <section className="hero-section-padding font-roboto">
      <h1 className="text-center mb-12">{puzzles.title}</h1>
      <h2 className="text-center font-light mb-4 text-gray-3 text-xl">
        {puzzles.sub_title}
      </h2>
      <div className="max-w-screen-lg mx-auto w-64 sm:w-11/12 lg:w-full">
        <Slider {...slickSettings}>
          {puzzles.puzzle_details.map((puzzle, index: number) => {
            return (
              <div key={`puzzle-${index}`} className="px-6">
                <Image
                  alt={puzzle.solution}
                  title={puzzle.solution}
                  src={puzzle.image}
                  className="max-w-full"
                />
              </div>
            )
          })}
        </Slider>
      </div>
      <MarkdownText
        text={puzzles.footer_text}
        className="text-center text-base text-gray-3 mb-10"
      />
    </section>
  )
}

export default PuzzlesPage

export const Head = () => {
  return (
    <Seo
      title={puzzles.meta_title}
      description={puzzles.meta_description}
      image={puzzles.meta_image}
    />
  )
}
