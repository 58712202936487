import { useSiteMetadata } from '../hooks/useSiteMetadata'
import { getPathname } from '../utils/url_helpers'

interface SeoProps {
  title?: string
  description?: string
  image?: string
  pathname?: string
  children?: JSX.Element
}

const defaultImage =
  'https://assets.cloud.pennylane.ai/pennylane_website/generic/generic_og.png'

const Seo = ({ title, description = '', image, children }: SeoProps) => {
  const {
    title: defaultTitle,
    description: defaultDescription,
    siteUrl,
  } = useSiteMetadata()

  const formatImagePath = () => {
    if (image?.includes('https://')) {
      return image
    } else {
      return image ? `${siteUrl}${image}` : defaultImage
    }
  }

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: formatImagePath(),
    url: `${siteUrl}${getPathname()}`,
  }

  return (
    <>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:url" content={seo.url} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
      <meta property="og:url" content={seo.url} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content={seo.image} />
      {children}
    </>
  )
}

export default Seo
