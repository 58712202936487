import { graphql, useStaticQuery } from 'gatsby'

export const useSiteMetadata = () => {
  const data = useStaticQuery<Queries.GetSiteMetadataQuery>(graphql`
    query GetSiteMetadata {
      site {
        pathPrefix
        siteMetadata {
          title
          description
          siteUrl
        }
      }
    }
  `)

  return {
    title: data.site?.siteMetadata?.title || '',
    description: data.site?.siteMetadata?.description || '',
    siteUrl: data.site?.siteMetadata?.siteUrl || '',
  }
}
